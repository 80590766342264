<!--贴心服务-贴心服务-->
<template>
  <div class="heart_service_page">
    <el-form inline size="mini">
      <el-form-item>
        <el-input v-model.trim="postdata.title" type="text" placeholder="标题"></el-input>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
          v-model="postdata.activityData"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="postdata.status" clearable placeholder="发布状态">
          <el-option label="已发布" :value="1"></el-option>
          <el-option label="已撤销" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="searchData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          plain
          @click="showAddDialog('add')"
        >新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      size="mini"
      border
      style="width: 100%;"
      :data="dataObj.list"
      :loading="postdata.loading"
    >
      <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
      <el-table-column prop="title" label="标题" align="center"></el-table-column>
      <el-table-column prop="recordTime" label="发布时间" align="center"></el-table-column>
      <el-table-column prop="Name" label="发布人" align="center"></el-table-column>
      <!-- <el-table-column prop="Type" label="公众参与小程序" align="center">
        <template slot-scope="{ row }">{{ row.type === 1 ? '是' : '否' }}</template>
      </el-table-column> -->
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="{ row }">{{ row.status === 1 ? '已发布' : '已撤销' }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" size="mini" @click="showAddDialog('look', row)">查看</el-button>
          <el-button
            type="text"
            v-if="row.status === 1"
            size="mini"
            @click="updateStatus(row.ID, 0)"
          >撤销发布</el-button>
          <el-button
            type="text"
            v-if="row.status === 0"
            size="mini"
            @click="updateStatus(row.ID, 1)"
          >发布</el-button>
          <el-button
            type="text"
            v-if="row.status === 0"
            size="mini"
            @click="updateStatus(row.ID, -1)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="postdata.page"
        :page-sizes="[ 20, 40, 100]"
        :page-size="postdata.perPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataObj.total"
      ></el-pagination>
    </div>

    <!-- 新增弹窗 beginning -->
    <el-dialog
      :class="'add_message_dialog ' + addPostdata.showType"
      :visible="addPostdata.show && addPostdata.showType === 'add'"
      :show-close="false"
      title="贴心服务"
      width="500px"
    >
      <el-form label-width="150px" :model="addPostdata" ref="addPostdata" :rules="rulesCustom">
        <el-form-item label="标题：" prop="title">
          <el-input
            v-model.trim="addPostdata.title"
            type="text"
            placeholder="请输入标题内容"
            :disabled="addPostdata.showType === 'look'"
          ></el-input>
        </el-form-item>
        <el-form-item class="form_item_ueditor" label="内容：" prop="content">
          <ueditor :defaultMsg="addPostdata.content" :config="config" ref="ueditor"></ueditor>
        </el-form-item>
        <!-- <el-form-item label="公众参与小程序：">
          <el-switch
            style="margin-top: 10px"
            v-model="addPostdata.type"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item> -->
      </el-form>
      <div class="add_dialog_footer" slot="footer">
        <el-button type="default" plain @click="closeDialog('addPostdata')" size="mini">取消</el-button>
        <el-button
          type="primary"
          @click="handleSubmit('addPostdata')"
          :loading="addPostdata.loading"
          size="mini"
        >确定</el-button>
      </div>
    </el-dialog>
    <!-- 新增弹窗 end -->

    <!-- 编辑弹窗 beginning -->
    <el-dialog
      :class="'add_message_dialog ' + addPostdata.showType"
      :visible="addPostdata.show && addPostdata.showType === 'look'"
      :show-close="false"
      title="消息通知"
      width="500px"
    >
      <el-form label-width="150px" :model="addPostdata" ref="addPostdata" :rules="rulesCustom">
        <el-form-item label="标题：" prop="title">
          <el-input
            v-model.trim="addPostdata.title"
            type="text"
            placeholder="请输入标题内容"
            :disabled="addPostdata.showType === 'look'"
          ></el-input>
        </el-form-item>
        <el-form-item class="form_item_ueditor" label="内容：" prop="content">
          <div v-html="addPostdata.content"></div>
        </el-form-item>
        <!-- <el-form-item label="公众参与小程序：">
          <el-switch
            style="margin-top: 10px"
            v-model="addPostdata.type"
            :active-value="1"
            :inactive-value="0"
            :disabled="addPostdata.showType === 'look'"
          />
        </el-form-item> -->
      </el-form>
      <div class="add_dialog_footer" slot="footer">
        <el-button type="default" plain @click="closeDialog('addPostdata')" size="mini">取消</el-button>
      </div>
    </el-dialog>
    <!-- 编辑弹窗 end -->
  </div>
</template>
<script>
import ueditor from "@/component/UEditor";

export default {
  components: {
    ueditor,
  },
  data() {
    return {
      // 富文本编辑器配置信息
      config: {
        initialFrameWidth: null,
        initialFrameHeight: 150,
      },
      postdata: {
        loading: false, // 列表数据加载状态
        page: 1,
        perPage: 10,
        title: "", // 标题
        status: null, // 1发布 0禁用 -1删除
        startDate: "", // 开始时间
        endDate: "", // 结束时间
        activityData: [],
      },
      // 列表数据
      dataObj: {
        list: [],
        pages: 0,
        total: 0,
      },
      // 新增消息入参
      addPostdata: {
        type: 0, // 1 公众参与小程序，默认
        loading: false, // 确定按钮加载状态
        show: false, // 是否显示新增弹窗
        showType: "add", // 显示类型 add添加；look查看
        title: "", // 标题
        content: "", // 内容
      },
      rulesCustom: {
        title: [{ required: true, message: "请输入标题内容", trigger: "blur" }],
        content: [
          { required: true, message: "请输入消息内容", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.netGetMessageList(); // 获取消息列表
  },
  methods: {
    /**
     * @description 新增消息
     * @param {String} 表单名称
     */
    handleSubmit(name) {
      this.addPostdata.content = this.$refs.ueditor.getUEContent();
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.addPostdata.loading = true;
          this.$post("admin/addPlatMsg", this.addPostdata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((Msg) => {
              this.$message({
                message: Msg || "新增成功",
                type: "success",
              });
              // 刷新数据
              this.postdata.page = 1;
              this.netGetMessageList();

              // 关闭弹窗
              this.addPostdata.show = false;
              this.addPostdata.loading = false;
            })
            .catch(() => {
              this.addPostdata.loading = false;
            });
        }
      });
    },
    /**
     * @description 重置表单数据
     */
    resetForm(name) {
      this.$refs[name].resetFields();
    },
    /**
     * @description 关闭弹窗
     * @param {String} name 数据对象名称
     */
    closeDialog(name) {
      this[name].show = false;
      this.resetForm(name);
    },
    /**
     * @description 显示弹窗
     * @param {String} type 显示类型 add添加；look查看
     */
    showAddDialog(type, data) {
      this.addPostdata.show = true;
      this.addPostdata.showType = type;

      if (data) {
        this.addPostdata.title = data.title;
        this.addPostdata.content = data.content;
        this.addPostdata.type = data.type;
      }
    },
    /**
     * @description 获取消息列表
     */
    netGetMessageList() {
      this.postdata.loading = true;
      if (this.postdata.activityData && this.postdata.activityData.length > 0) {
        this.postdata.startDate = this.postdata.activityData[0] + " 00:00:00";
        this.postdata.endDate = this.postdata.activityData[1] + " 23:59:59";
      }
      this.postdata.status =
        this.postdata.status === "" ? null : this.postdata.status;
      this.$post("admin/queryPlatMsg", this.postdata, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.dataObj.list = res.data;
          this.dataObj.pages = res.pageInfo.TotalPage;
          this.dataObj.total = res.pageInfo.Total;
          this.postdata.loading = false;
        })
        .catch(() => {
          this.postdata.loading = false;
        });
    },
    /**
     * @description 查询
     */
    searchData() {
      this.postdata.page = 1;
      this.netGetMessageList();
    },
    /**
     * @description 修改状态
     * @param {Number} id 消息id
     * @param {Number} status 消息状态 1发布 0禁用 -1删除
     */
    updateStatus(id, status) {
      let tip = "";
      if (status === 1) {
        tip = "确定发布该消息？";
      } else if (status === 0) {
        tip = "确定撤销该消息？";
      } else if (status === -1) {
        tip = "确定删除该消息？";
      }
      this.$confirm(tip, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post(
          "admin/updatePlatMessage",
          {
            id: id,
            status: status,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.netGetMessageList();
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "操作失败!",
            });
          });
      });
    },

    /**
     * @description 每页条数
     */
    handleSizeChange(pageSize) {
      this.postdata.page = 1;
      this.postdata.perPage = pageSize;
      this.netGetMessageList();
    },

    /**
     * @description 页码
     */
    handleCurrentChange(page) {
      this.postdata.page = page;
      this.netGetMessageList();
    },
  },
};
</script>
<style lang="scss">
.heart_service_page {
  p {
    margin: 5px 0;
  }
  .page_box {
    padding: 30px 0;
    text-align: center;
  }
  .v-modal,
  .el-dialog__wrapper {
    z-index: 999 !important;
  }

  .add_message_dialog {
    &.look {
      .form_item_ueditor {
        .el-form-item__content {
          padding: 10px 0;
        }
      }
    }
  }
}
</style>
